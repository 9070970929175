import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function SiteMap() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth <= 1024
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    const updateScreenSize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768);
      setIsTablet(width >= 768 && width <= 1024);
    };

    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="bg-white md:mt-36 mt-32 container md:py-20 py-10">
      <div
        className={`flex justify-center w-full items-start gap-12 px-5 ${
          isMobile
            ? "flex-col"
            : isTablet
            ? "flex-wrap justify-center gap-y-16"
            : ""
        }`}
      >
        {isTablet ? (
          <>
            {/* Top Row in Tablet View */}
            <div className="flex w-full justify-center gap-12">
              <Section
                title="About Us"
                links={[
                  { to: "/about-us#company", text: "Company" },
                  { to: "/about-us#csr", text: "CSR" },
                  {
                    to: "/about-us#services",
                    text: "Our Manufacturing Resources",
                  },
                  {
                    to: "/about-us#rnd",
                    text: "Research, Development & Innovation",
                  },
                  {
                    to: "/about-us/company-updates",
                    text: "Corporate Announcement",
                  },
                ]}
              />
              <Section
                title="Products"
                links={[
                  { to: "/products", text: "Piston Valves" },
                  {
                    to: "/products",
                    text: "Steam Trapping & Condensate Recovery",
                  },
                  { to: "/products", text: "Jointing Sheets & Gaskets" },
                  { to: "/safety-valves", text: "Safety and Control Valves" },
                ]}
              />
              <Section
                title="Solutions"
                links={[
                  { to: "/industry/pharma-industry", text: "Pharmaceutical" },
                  { to: "/industry/brewery-industry", text: "Brewery" },
                  { to: "/industry/sugar-industry", text: "Sugar" },
                  {
                    to: "/industry/captive-cogen-industry",
                    text: "Captive Cowgen",
                  },
                  {
                    to: "/industry/chemical-petrochemical-plants-industry",
                    text: "Chemical",
                  },
                  { to: "/industry/dairy-industry", text: "Dairy" },
                  { to: "/industry/edible-oil-industry", text: "Edible Oil" },
                  { to: "/industry/fertilizer-industry", text: "Fertilizer" },
                  { to: "/industry/hotel-industry", text: "Hotel" },
                  {
                    to: "/industry/aerated-block-industry",
                    text: "Aerated Concrete",
                  },
                  { to: "/industry/rice-industry", text: "Rice" },
                  { to: "/industry/rubber-industry", text: "Rubber" },
                  { to: "/industry/soap-industry", text: "Soap" },
                  { to: "/industry/textiles-industry", text: "Textile" },
                  { to: "/industry/tyre-manufacturing-industry", text: "Tire" },
                ]}
              />
            </div>

            {/* Bottom Row in Tablet View */}
            <div className="flex w-full justify-center gap-12">
              <Section
                title="Careers"
                links={[
                  { to: "/careers", text: "Why Us" },
                  { to: "/partner-with-us", text: "Join Us" },
                ]}
              />
              <Section
                title="Insights"
                links={[{ to: "/insights", text: "Insights" }]}
              />
              <Section
                title="Legal"
                links={[
                  { to: "/privacy-policy", text: "Privacy & Policy" },
                  { to: "/terms-conditions", text: "Terms & Conditions" },
                ]}
              />
            </div>
          </>
        ) : (
          <>
            {/* Normal Layout for Desktop & Mobile */}
            <Section
              title="About Us"
              links={[
                { to: "/about-us#company", text: "Company" },
                { to: "/about-us#csr", text: "CSR" },
                {
                  to: "/about-us#services",
                  text: "Our Manufacturing Resources",
                },
                {
                  to: "/about-us#rnd",
                  text: "Research, Development & Innovation",
                },
                {
                  to: "/about-us/company-updates",
                  text: "Corporate Announcement",
                },
              ]}
            />
            <Section
              title="Products"
              links={[
                { to: "/products", text: "Piston Valves" },
                {
                  to: "/products",
                  text: "Steam Trapping & Condensate Recovery",
                },
                { to: "/products", text: "Jointing Sheets & Gaskets" },
                { to: "/products", text: "Safety and Control Valves" },
              ]}
            />
            <Section
              title="Solutions"
              links={[
                { to: "/industry/pharma-industry", text: "Pharmaceutical" },
                { to: "/industry/brewery-industry", text: "Brewery" },
                { to: "/industry/sugar-industry", text: "Sugar" },
                {
                  to: "/industry/captive-cogen-industry",
                  text: "Captive Cowgen",
                },
                {
                  to: "/industry/chemical-petrochemical-plants-industry",
                  text: "Chemical",
                },
                { to: "/industry/dairy-industry", text: "Dairy" },
                { to: "/industry/edible-oil-industry", text: "Edible Oil" },
                { to: "/industry/fertilizer-industry", text: "Fertilizer" },
                { to: "/industry/hotel-industry", text: "Hotel" },
                {
                  to: "/industry/aerated-block-industry",
                  text: "Aerated Concrete",
                },
                { to: "/industry/rice-industry", text: "Rice" },
                { to: "/industry/rubber-industry", text: "Rubber" },
                { to: "/industry/soap-industry", text: "Soap" },
                { to: "/industry/textiles-industry", text: "Textile" },
                { to: "/industry/tyre-manufacturing-industry", text: "Tire" },
              ]}
            />
            <Section
              title="Careers"
              links={[
                { to: "/careers", text: "Why Us" },
                { to: "/partner-with-us", text: "Join Us" },
              ]}
            />
            <Section
              title="Insights"
              links={[{ to: "/insights", text: "Insights" }]}
            />
            <Section
              title="Legal"
              links={[
                { to: "/privacy-policy", text: "Privacy & Policy" },
                { to: "/terms-conditions", text: "Terms & Conditions" },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
}

function Section({ title, links }) {
  return (
    <div className="flex-1">
      <h3 className="font-bold text-4xl mb-6 text-gray-800">{title}</h3>
      <div className="space-y-5">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            className="hover:text-[#DB1F51] transition-all duration-300 block text-2xl"
          >
            {link.text}
          </Link>
        ))}
      </div>
    </div>
  );
}
