import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NotFound from "../assets/images/notfound.svg";
import UKL404 from "../assets/images/ukl404.png";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center text-center">
      {/* Clickable logo at the top center */}
      <img
        src={UKL404}
        alt="Go Home"
        className="w-44 cursor-pointer absolute top-6 transition-transform duration-200 hover:scale-110"
        onClick={() => navigate("/")}
      />

      {/* Centered 404 Image */}
      <div className="flex justify-center items-center">
        <img
          src={NotFound}
          alt="Not Found"
          className="w-full min-h-screen max-h-screen"
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
